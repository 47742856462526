import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import joinClassNames from "classnames";

import SearchIcon from "icons/search.svg?react";
import CloseIcon from "icons/close.svg?react";

import Input from "basics/input";
import { BlankButton } from "basics/button";

import classes from "./styles/classes.module.scss";

interface Props {
  isDisabled?: boolean;
  isBlock?: boolean;
  name?: string;
  placeholder?: string;
  onSearchChange: (value: string) => void;
  className?: string;
  classNames?: Partial<{ wrapper: string }>;
  initialValue?: string;
  onClose?: VoidFunction;
  autoFocus?: boolean;
}

const Search: FC<Props> = ({
  isDisabled = false,
  autoFocus = false,
  onClose,
  onSearchChange,
  placeholder = "",
  initialValue = "",
  name,
  className,
  ...restProps
}) => {
  const [value, setValue] = useState(initialValue || "");

  const onSearchChangeLazy = useCallback(
    debounce(text => {
      onSearchChange(text);
    }, 1000),
    [onSearchChange],
  );

  useEffect(() => () => onSearchChangeLazy.cancel(), []);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value;
    setValue(searchText);
    onSearchChangeLazy(searchText);
  };

  return (
    <Input
      autoFocus={autoFocus}
      className={joinClassNames(classes.input, className)}
      value={value}
      name={name}
      onChange={onChange}
      disabled={isDisabled}
      placeholder={placeholder}
      before={<SearchIcon className={classes.searchIcon} />}
      after={
        (value || onClose) && (
          <BlankButton
            className={classes.clearButton}
            onClick={() => {
              setValue("");
              onSearchChange("");
              onClose?.();
            }}
          >
            <CloseIcon className={classes.closeIcon} />
          </BlankButton>
        )
      }
      {...restProps}
    />
  );
};

export default Search;
